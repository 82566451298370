import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';

export const Text = styled.span({
  fontFamily: token('font.family.brand.body'),
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: '32px',
});

export const TextWC = styled.span({
  fontFamily: token('font.family.brand.heading'),
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.25rem',
});

export const BaseButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: token('color.background.neutral.subtle'),
  cursor: 'pointer',
  color: token('color.text.subtle'),
  justifyContent: 'center',
  borderRadius: '3px',
  border: 'none',
  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: token('color.background.neutral.hovered'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.pressed'),
  },
  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
  },
});

export const IconButton = styled.button<{ isSelected?: boolean }>({
  display: 'flex',
  alignItems: 'center',
  padding: token('space.050'),
  borderRadius: '50%',
  cursor: 'pointer',
  border: 0,

  backgroundColor: (props) =>
    props.isSelected
      ? token('color.background.selected')
      : token('color.background.neutral.subtle'),

  '& path': {
    fill: (props) => (props.isSelected ? token('color.icon.selected') : token('color.icon')),
  },

  '&:hover': {
    backgroundColor: (props) =>
      props.isSelected
        ? token('color.background.selected')
        : token('color.background.neutral.subtle.hovered'),
  },

  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },

  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
  },
});

export const IconButtonWC = styled.button<{ isSelected?: boolean }>({
  display: 'flex',
  alignItems: 'center',
  padding: token('space.025'),
  borderRadius: '50%',
  cursor: 'pointer',
  border: 0,

  backgroundColor: (props) =>
    props.isSelected
      ? token('color.background.neutral.subtle.pressed')
      : token('color.background.neutral.subtle'),

  '& path': {
    fill: token('color.icon'),
  },

  '&:hover': {
    backgroundColor: (props) =>
      props.isSelected
        ? token('color.background.accent.gray.subtlest')
        : token('color.background.neutral.subtle.hovered'),
  },

  '&:active': {
    backgroundColor: token('color.background.neutral.subtle.pressed'),
  },

  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
  },
});
